import React from 'react';

import { Link, usePage } from '@inertiajs/react';
import route from 'ziggy-js';

import Button from '@/Components/Button/Button';
import { AuthenticatedPageProps } from '@/types';

const ImpersonateBanner = () => {
  const { auth } = usePage<AuthenticatedPageProps>().props;

  return (
    <>
      {auth?.user?.is_impersonated && (
        <div className="flex justify-between items-center h-5 bg-red-500 text-white px-4.5 py-4">
          <div>Impersonate mode</div>
          <Button
            as={Link}
            href={route('admin.impersonate.leave')}
            variant="destructive"
          >
            Quitter
          </Button>
        </div>
      )}
    </>
  );
};

export default ImpersonateBanner;
